import * as React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Map from "../components/map"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const headerImg = post.frontmatter.topImage
    ? getImage(post.frontmatter.topImage)
    : null
  return (
    <Layout>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">
            <Link to="/">{post.frontmatter.title}</Link>
          </h1>
          <p>{post.frontmatter.date}</p>
        </header>

        {headerImg && <GatsbyImage image={headerImg} alt="Blog header image" />}

        {post.frontmatter.latitude && post.frontmatter.latitude && (
          <Map
            latitude={post.frontmatter.latitude}
            longitude={post.frontmatter.longitude}
          />
        )}
        {post.frontmatter.mapPoints && (
          <Map
            mapPoints={post.frontmatter.mapPoints}
            races={post.frontmatter.races ?? []}
          />
        )}

        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
      </article>
      <div className="footer-link">
        {data.prevPost && <Link to={data.prevPost.fields.slug}>Prev</Link>}
        <Link to="/" className="back-home">
          Back home
        </Link>
        {data.nextPost && <Link to={data.nextPost.fields.slug}>Next</Link>}
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $nextPostId: String
    $previousPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    nextPost: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
    }
    prevPost: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
        description
        longitude
        latitude
        topImage {
          childImageSharp {
            gatsbyImageData
          }
        }
        mapPoints {
          position {
            lat
            lng
          }
          speed
          time
        }
        races {
          start
          end
        }
      }
    }
  }
`
